#ReactBackgroundSlider:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.logo-img {
  object-fit: contain;
  width: 20%;
  height: auto;
  animation: logo-img 1.5s ease-in-out;
}

@keyframes logo-img {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
